<script>
  import { preventDefault, stopPropagation } from "svelte/legacy"

  import { slide } from "svelte/transition"

  let isMenuOpen = $state(false)

  function clickOutside(node) {
    const handleClick = (event) => {
      if (!node.contains(event.target)) {
        node.dispatchEvent(new CustomEvent("outclick"))
      }
    }

    document.addEventListener("click", handleClick, true)

    return {
      destroy() {
        document.removeEventListener("click", handleClick, true)
      },
    }
  }

  function escapeKey(node) {
    const handleKey = (event) => {
      if (event.key === "Escape") {
        node.dispatchEvent(new CustomEvent("escapekey"))
      }
    }

    document.addEventListener("keydown", handleKey, true)

    return {
      destroy() {
        document.removeEventListener("keydown", handleKey, true)
      },
    }
  }
</script>

<header
  class="fixed top-4 w-[90%] md:w-full md:max-w-[700px] left-1/2 -translate-x-1/2 z-30"
>
  <!-- Main header -->
  <div
    class="bg-white md:bg-gradient-to-r md:from-blue-50/30 md:via-white/90 md:to-blue-50/90 md:backdrop-blur-sm rounded-t-3xl rounded-b-3xl px-6 shadow-sm"
    use:clickOutside
    use:escapeKey
    onoutclick={() => (isMenuOpen = false)}
    onescapekey={() => (isMenuOpen = false)}
  >
    <div class="flex items-center justify-between h-14">
      <!-- Site branding -->
      <div class="flex items-center">
        <a class="inline-flex" href="/" aria-label="Promo Amp Home">
          <img src="/logo.svg" alt="Promo Amp Home" width="120" />
        </a>
      </div>

      <!-- Desktop Navigation -->
      <nav class="hidden md:flex items-center space-x-6">
        <a
          href="/pricing"
          rel="external"
          class="text-base hover:underline font-medium text-gray-900">Pricing</a
        >
        <!-- <a
          href="/giveaways"
          rel="external"
          class="text-base hover:underline font-medium text-gray-900"
          >Giveaways</a
        > -->
        <a
          href="/sign-in"
          rel="external"
          class="text-base hover:underline font-medium text-gray-900">Log in</a
        >
        <a
          href="/sign-up"
          rel="external"
          class="text-base hover:underline font-medium rounded-full bg-black text-white px-4 py-2"
          >Sign Up</a
        >
      </nav>

      <!-- Mobile menu button -->
      <button
        class="md:hidden p-2"
        aria-label="Menu"
        onclick={stopPropagation(
          preventDefault(() => (isMenuOpen = !isMenuOpen)),
        )}
        type="button"
      >
        <svg
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          {#if !isMenuOpen}
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          {:else}
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          {/if}
        </svg>
      </button>
    </div>

    <!-- Mobile menu panel -->
    {#if isMenuOpen}
      <div class="pt-4 pb-6 md:hidden" transition:slide={{ duration: 300 }}>
        <nav class="flex flex-col space-y-6">
          <a
            href="/pricing"
            rel="external"
            class="text-xl font-medium text-gray-900 tracking-tight"
            onclick={() => (isMenuOpen = false)}
          >
            Pricing
          </a>
          <a
            href="/giveaway"
            rel="external"
            class="text-xl font-medium text-gray-900 tracking-tight"
            onclick={() => (isMenuOpen = false)}
          >
            Giveaway
          </a>
          <a
            href="/sign-in"
            rel="external"
            class="text-xl font-medium text-gray-900 tracking-tight"
            onclick={() => (isMenuOpen = false)}
          >
            Log in
          </a>
          <a
            href="/sign-up"
            rel="external"
            class="text-base text-center hover:underline font-medium rounded-3xl bg-black text-white px-4 py-3"
            >Sign Up</a
          >
        </nav>
      </div>
    {/if}
  </div>
</header>
